@font-face {
font-family: '__tiempos_bcc0ef';
src: url(/_next/static/media/8f2ae2391c503bd7-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: Regular;
}

@font-face {
font-family: '__tiempos_bcc0ef';
src: url(/_next/static/media/9bc516ef799fc0ae-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: Bold;
}

@font-face {
font-family: '__tiempos_bcc0ef';
src: url(/_next/static/media/560b14e49de6320e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: Black;
}

@font-face {
font-family: '__tiempos_bcc0ef';
src: url(/_next/static/media/14cb4dbbc820786e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: Light;
}

@font-face {
font-family: '__tiempos_bcc0ef';
src: url(/_next/static/media/ac83e24244f0bd97-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: Medium;
}

@font-face {
font-family: '__tiempos_bcc0ef';
src: url(/_next/static/media/641561a77f59f622-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: Semibold;
}@font-face {font-family: '__tiempos_Fallback_bcc0ef';src: local("Arial");ascent-override: 96.96%;descent-override: 25.20%;line-gap-override: 0.00%;size-adjust: 99.22%
}.__className_bcc0ef {font-family: '__tiempos_bcc0ef', '__tiempos_Fallback_bcc0ef'
}.__variable_bcc0ef {--font-tiempos: '__tiempos_bcc0ef', '__tiempos_Fallback_bcc0ef'
}

@font-face {
font-family: '__futuraPt_f3b362';
src: url(/_next/static/media/d7dba6de554ee170-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: Light;
}

@font-face {
font-family: '__futuraPt_f3b362';
src: url(/_next/static/media/6f52b1db93860cdf-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: Regular;
}

@font-face {
font-family: '__futuraPt_f3b362';
src: url(/_next/static/media/4d53b7e83171101c-s.p.otf) format('opentype');
font-display: swap;
font-weight: 450;
font-style: Medium;
}

@font-face {
font-family: '__futuraPt_f3b362';
src: url(/_next/static/media/5acd9bf4ad6d3d39-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: Bold;
}

@font-face {
font-family: '__futuraPt_f3b362';
src: url(/_next/static/media/4b49359b61dea8d4-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: Heavy;
}

@font-face {
font-family: '__futuraPt_f3b362';
src: url(/_next/static/media/d56d7d6f543df4bf-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: ExtraBold;
}@font-face {font-family: '__futuraPt_Fallback_f3b362';src: local("Arial");ascent-override: 111.54%;descent-override: 34.07%;line-gap-override: 0.00%;size-adjust: 88.04%
}.__className_f3b362 {font-family: '__futuraPt_f3b362', '__futuraPt_Fallback_f3b362'
}.__variable_f3b362 {--font-futura: '__futuraPt_f3b362', '__futuraPt_Fallback_f3b362'
}

